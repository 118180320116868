<template>
  <modal :title="ModalTitle" :is-show="isShow" v-if="isShow" @close-modal="setVisible(false)">
    <div class="edit-account">
      <table>
        <!--角色名称-->
        <tr>
          <td class="label">{{$t('RoleName')}}：</td>
          <td><input class="ipt" type="text" autocomplete="off" v-model.trim="role.name"/></td>
        </tr>
        <!--描述-->
        <tr>
          <td class="label">{{$t('Description')}}：</td>
          <td><input class="ipt" type="text" autocomplete="off" v-model.trim="role.remark"/></td>
        </tr>
        <!--菜单-->
        <tr>
          <td class="label" style="vertical-align:top">{{$t('Menu')}}：</td>
          <td>
            <el-tree :data="menuTrees"
                     show-checkbox
                     node-key="id"
                     :default-expanded-keys="menuTrees.filter(item=>item.children.length).map(item=>item.id)"
                     :default-checked-keys="defaultCheckedKeys"
                     :props="{label:'menuName'}"
                     ref="tree">
            </el-tree>
          </td>
        </tr>
        <!--状态-->
        <tr>
          <td class="label">{{$t('Status')}}：</td>
          <td>
            <radio v-model="role.isValid" v-for="(value,key) of $t('statusObj')" :label="key" :key="key">
              {{value}}
            </radio>
          </td>
        </tr>
      </table>
    </div>
    <div class="actions">
      <button class="button button-primary m-r" :disabled="disableSubmit" @click="onSubmit">{{$t('OK')}}</button>
      <button class="button" @click="setVisible(false)">{{$t('Cancel')}}</button>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import Radio from '../../../../components/Radio';
  import {getAdminRoleDetail, saveAdminRole, getCount} from '../../../../api/admin-role';

  export default {
    name: 'EditRole',
    props: {
      show: Boolean,
      roleId: String
    },
    data() {
      return {
        isShow: false,
        userCount: 0,
        role: {
          name: '',
          remark: '',
          isValid: 'Y',
          permission: ''
        },
        menuTrees: [],
        defaultCheckedKeys: []
      };
    },
    computed: {
      ModalTitle() {
        return this.roleId ? this.$t('ModifyRole') : this.$t('NewRole');
      },
      disableSubmit: function () {
        return !this.role.name;
      }
    },
    components: {Radio, Modal},
    methods: {
      setVisible(visible) {
        this.$emit('update:show', visible);
      },
      // 當"修改角色"的modal顯示出來時，載入該角色的權限並顯示出來
      updateMenuTrees(datas) {
        let parentList = datas.filter(item => (item.parentId === 'ROOT'));
        const childrenList = datas.filter(item => (item.parentId !== 'ROOT'));
        return parentList.map(item => {
          item.children = childrenList.filter(cItem => cItem.parentId === item.id);
          return item;
        });
      },
      onSubmit() {
        this.userCount = 0;
        getCount(this.roleId).then(res => {
          let count = res.value;
          if (this.role.isValid === 'N' && count > 0) {
            this.$message.error('此角色有用户使用，不能禁用！');
          } else {
            let datas = {...this.role};
            // datas.menuIds = this.$refs.tree.getCheckedKeys(false, true).join(';');
            // 將子節點（選中），父節點（選中），父節點（半選）的ids 加在一起
            // 子節點（選中），父節點（選中）
            const selectedArray = this.$refs.tree.getCheckedKeys(false, true);
            // 父節點（半選）
            const halfSelectedArray = this.$refs.tree.getHalfCheckedKeys();
            const selected = [...selectedArray, ...halfSelectedArray];
            datas.menuIds = selected.join(';');
            saveAdminRole(datas).then(res => {
              // 要求母組件列表刷新
              this.$emit('reload-list', 1);
              this.$message.success(this.$t('SaveSuccessfully'));
              this.setVisible(false);
              this.$store.dispatch('adminUser/getRoleList');
            });
          }
        });
      }
    },
    watch: {
      // 當"修改角色"的modal顯示出來時，載入該角色的權限並顯示出來
      show: function (value) {
        this.isShow = value;
        if (this.isShow) {
          getAdminRoleDetail(this.roleId || '').then(res => {
            const menuTrees = this.updateMenuTrees(res.value.menuTrees);
            const role = res.value.role || this.$options.data().role;
            const defaultCheckedKeys = res.value.menuTrees.filter(item => item.isCheck).map(item => item.id);
            Object.assign(this.$data, {menuTrees, role, defaultCheckedKeys});
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .edit-account{
    text-align:left;padding:24px 30px;font-size:16px;
    td{padding-bottom:20px;vertical-align:middle;}
    .label{
      width:80px;white-space:nowrap;
      &.v-a-t{vertical-align:top;}
    }
    .ipt{
      width:330px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .role-item{
      margin-bottom:10px;
      &:last-child{margin-bottom:0;}
      /deep/ .radio{min-width:105px;}
    }
    /deep/ .radio{
      vertical-align:top;
      .label{color:#321908;}
    }
  }
  .actions{padding:4px 0 24px;}
</style>
