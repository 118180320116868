<template>
  <div class="main">
    <div class="filter cl">
      <!--角色名称-->
      <div class="item cl">
        <div class="label">{{$t('RoleName')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.name"/>
        </div>
      </div>
      <!--状态-->
      <div class="item cl">
        <div class="label">{{$t('Status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable v-model="filterData.status" size="small" :placeholder="$t('All')">
            <el-option v-for="item in statusOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--操作按钮-->
      <div class="fl">
        <button class="button button-primary small" type="button" @click="getList(1)">{{$t('search')}}</button>
      </div>
    </div>
    <div>
      <button class="button button-primary" type="button" @click="setEditModal()">{{$t('NewRole')}}</button>
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th class="checkbox"><img src="../../../assets/images/checkbox.png"/></th>
          <th>{{$t('RoleName')}}</th>
          <th>{{$t('Description')}}</th>
          <th>{{$t('Status')}}</th>
          <th width="60">{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody class="tbody">
        <tr v-for="(item,index) of list" :key="item.id">
          <td class="checkbox"><img src="../../../assets/images/checkbox.png"/></td>
          <td>{{item.name}}</td>
          <td>{{item.remark}}</td>
          <td :class="item.isValid==='Y'?'green':'red'">{{$t('statusObj')[item.isValid]}}</td>
          <td class="nowrap">
            <a class="btn-item" href="javascript:;" @click="setEditModal(item.id)">
              <img src="../../../assets/images/icon/pen.png"/>
            </a>
            <a class="btn-item" href="javascript:;" @click="deleteAccount(index,item.id)">
              <img src="../../../assets/images/icon/ashcan.png"/>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
    <edit-role :show.sync="showEditModal" :role-id="roleId" @reload-list="getList"/>
  </div>
</template>
<script>
  import EditRole from './components/EditRole';
  import Empty from '../../../components/Empty';
  import {getAdminRoleList, deleteAdminRole, getCount} from '../../../api/admin-role';

  export default {
    name: 'AdminRoleManagement',
    data() {
      return {
        showEditModal: false,
        list: [],
        totalPages: 1,
        isEmpty: false,
        roleId: '',
        statusOptions: [
          {value: 'Y', label: this.$t('statusObj')['Y']},
          {value: 'N', label: this.$t('statusObj')['N']}
        ],
        filterData: {
          name: '',
          status: ''
        }
      };
    },
    components: {Empty, EditRole},
    created() {
      this.getList();
    },
    methods: {
      // 新增或编辑账号弹窗
      setEditModal(roleId) {
        this.roleId = roleId;
        this.showEditModal = true;
      },
      getList(page = 1) {
        const data = {page, perPage: 10, name: this.filterData.name, isValid: this.filterData.status};
        getAdminRoleList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      // 删除帐号
      deleteAccount(index, roleId) {
        getCount(roleId).then(res => {
          let count = res.value;
          if (count > 0) {
            this.$message.error('此角色有用户使用，不能删除！');
          } else {
            this.$msgbox({
              title: this.$t('Prompt'),
              message: this.$t('confirm.delete'),
              showCancelButton: true,
              lockScroll: false,
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  deleteAdminRole(roleId).then(res => {
                    instance.confirmButtonLoading = false;
                    done();
                    this.$message.success('刪除成功!');
                    this.list.splice(index, 1);
                    if (!this.list.length) {
                      this.getList();
                    }
                    this.$store.dispatch('adminUser/getRoleList');
                  }).catch(() => {
                    instance.confirmButtonLoading = false;
                  });
                } else {
                  done();
                }
              }
            }).then(() => {
            }).catch(() => {
            });
          }
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .green{color:#00b680;}
    .red{color:#ff6f61;}
  }
  .filter{
    .item{
      float:left;margin-bottom:24px;margin-right:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:160px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:160px;vertical-align:top;}
  }
</style>
